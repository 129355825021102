<template>
    <app-wrapper>
        <template v-slot:child>
            <template v-if="isLoading"><Spinner /></template>
        </template>
    </app-wrapper>
</template>

<script>
import { onMounted, computed, reactive } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import AppWrapper from '@/layout/AppWrapper'

import Spinner from '@/components/Spinner'

export default {
    name: 'PaymentProcess',

    components: {
        AppWrapper,
        Spinner
    },

    setup() {
        const router = useRouter()
        const route = useRoute()
        const store = useStore()
        const { id } = route.params
        const transactionId = route.query.trxref

        const isLoading = computed(() => store?.state.Invoice.isLoading)

        const state = reactive({
            invoiceReference: id ?? transactionId
        })

        onMounted(async () => {
            const verifyPayload = {
                reference: state.invoiceReference.toString(),
                type: 'verify'
            }

            console.log('verifyPayload: ', verifyPayload)
            await store.dispatch('Settings/verifyPayment', verifyPayload).then(async (response) => {
                if (response.status) {
                    await store.dispatch('Auth/getUserData')
                    router.push({
                        name: 'PaymentInvoice',
                        query: {
                            trxref: state.invoiceReference.toString(),
                            reference: state.invoiceReference.toString()
                        }
                    })
                }
            })
        })

        return {
            state,
            isLoading
        }
    }
}
</script>

<style scoped></style>